import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { CloseIcon, GlassIcon } from "../../../../assets/icons";
import useChats from "../../../../hooks/api/useChatsAPI.js";
import Chats from "../Chats/index.jsx";
import { ChatSkeleton } from "../Skeleton/index.jsx";
import styles from "./style.module.scss";
import SearchResult from "../SearchResult/index.jsx";

const ChatSidebar = ({
  data,
  isLoading,
  chatFetchNextPage,
  chatHasNextPage,
  setLeadFormOpen,
}) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 500);

  const { contacts, contactLoading } = useChats({
    search: debouncedSearch,
  });

  const chats = useMemo(() => {
    if (data) {
      const groupedChats = {};

      data?.pages?.forEach((page) => {
        page?.payload?.chats?.forEach((item) => {
          const formattedDate = moment(item?.LastMessage?.Date).format(
            "MMMM DD, YYYY"
          );
          const time = moment(item?.LastMessage?.Date)
            .add(5, "hours")
            .format("HH:mm");

          const chatItem = {
            name: `${item.Chat.LastName} ${item.Chat.FirstName}`,
            last_name: item.Chat.LastName,
            first_name: item.Chat.LastName,
            platform: "telegram.com",
            time: time,
            unread: item?.Dialog?.UnreadCount,
            avatar: item?.Agent.Photo || "/chat_ava.jpg",
            assigned: item?.IsAssigned && item?.Agent?.LastName,
            deleted: item?.Chat?.Deleted,
            id: `${item?.Agent.Id}-${item?.Chat.ID}`,
            hash: item?.Chat.AccessHashStr,
            chat_id: item?.Chat.ID,
            last_message: item?.LastMessage?.Message?.replace(/\n/g, " "),
            assigned_agent: `${item?.Agent?.LastName} ${item?.Agent?.FirstName}`,
          };

          if (!groupedChats[formattedDate]) {
            groupedChats[formattedDate] = [];
          }

          groupedChats[formattedDate].push(chatItem);
        });
      });

      return Object.keys(groupedChats)
        .sort((a, b) =>
          moment(b, "MMMM DD, YYYY").diff(moment(a, "MMMM DD, YYYY"))
        )
        .map((date) => ({
          date: date,
          users: groupedChats[date],
        }));
    }

    return [];
  }, [data]);

  const formattedContacts = useMemo(() => {
    if (contacts?.payload?.response) {
      return contacts?.payload?.response?.map((item) => {
        return {
          name: `${item?.LastName} ${item?.FirstName}`,
          username: item?.Username,
          platform: "telegram.com",
          avatar: item?.Photo || "/chat_ava.jpg",
          id: item?.ID,
          hash: item?.AccessHashStr,
          last_message: item?.LastMessage?.Message?.replace(/\n/g, " ") || "",
          unread: item?.Dialog?.UnreadCount || 0,
          assigned_agent: item?.Agent
            ? `${item?.Agent.LastName} ${item?.Agent.FirstName}`
            : null,
          assigned: item?.IsAssigned,
          time: item?.LastMessage
            ? moment(item?.LastMessage.Date).format("hh:mm A")
            : null,
          chat_id: item?.Chat?.ID,
        };
      });
    }

    return [];
  }, [contacts]);

  const handleClearSearch = () => {
    setSearch("");
    navigate(`/chat`);
  };

  return (
    <div id="chat_sidebar" className={styles.sidebar}>
      <div className={styles.search}>
        <TextField
          style={{
            background: "#fff",
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {contactLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <GlassIcon />
                )}
              </InputAdornment>
            ),
            endAdornment: formattedContacts?.length ? (
              <InputAdornment>
                <Box className={styles.close_icon} onClick={handleClearSearch}>
                  <CloseIcon />
                </Box>
              </InputAdornment>
            ) : null,
          }}
        />
      </div>

      {formattedContacts?.length ? (
        <SearchResult
          formattedContacts={formattedContacts}
          setLeadFormOpen={setLeadFormOpen}
        />
      ) : (
        <>
          {isLoading ? (
            <ChatSkeleton />
          ) : (
            <Chats
              key={chats}
              chatFetchNextPage={chatFetchNextPage}
              chatHasNextPage={chatHasNextPage}
              chats={chats}
              setLeadFormOpen={setLeadFormOpen}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ChatSidebar;
