import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { telegramApi } from "../../utils/telegramApi";

const useChats = (props) => {
  const {
    data,
    fetchNextPage: chatFetchNextPage,
    hasNextPage: chatHasNextPage,
    isLoading,
    isError,
    refetch,
  } = useInfiniteQuery(
    ["GET_CHATS"],
    ({ pageParam = { limit: 10, offset: 0 } }) => {
      return telegramApi.get("telegram/chats", {
        params: {
          ...pageParam,
        },
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const limit = 10;
        const offset = allPages?.length * 10;
        const totalCount = lastPage?.payload?.chats?.length;

        if (!lastPage?.payload?.chats?.length) return false;

        return { limit, offset };
      },
      // enabled: true,
      refetchOnWindowFocus: false,
      refetchInterval: 5000,
      onSuccess: props?.onChatSuccess,
    }
  );

  const { data: chat, refetch: chatRefetch } = useQuery(
    ["GET_CHAT", props?.id, props?.hash],
    () => telegramApi.get(`telegram/chat/${props?.id}/${props?.hash}`),
    {
      enabled: !!props?.id,
      refetchInterval: 5000,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: messages,
    fetchNextPage,
    hasNextPage,
    isLoading: messagesLoading,
    isFetching,
    refetch: messageRefetch,
  } = useInfiniteQuery(
    ["GET_MESSAGES", props?.id, props?.hash],
    ({ pageParam = 0 }) => {
      return telegramApi.get(`telegram/messages/${props?.id}/${props?.hash}`, {
        params: {
          offset_id: pageParam,
          limit: 100,
        },
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const lastMessage = lastPage?.payload?.messages?.Messages?.slice(-1)[0];
        return lastMessage?.ID;
      },
      enabled: !!props?.id,
      refetchInterval: 4000,
      refetchOnWindowFocus: false,
    }
  );

  const agentAssign = useMutation((data) =>
    telegramApi.post("chat/assign", data)
  );

  const uploadFile = useMutation((data) => telegramApi.post("upload", data));

  const { mutate: sendMessage, isLoading: sendMessageLoading } = useMutation(
    (data) =>
      telegramApi.post(
        `telegram/message/sent/${data?.id}/${data?.hashId}`,
        data
      )
  );

  const {
    data: contacts,
    refetch: refetchContacts,
    isLoading: contactLoading,
  } = useQuery(
    ["GET_CONTACT", props?.search],
    () =>
      telegramApi.get("telegram/contact", {
        params: {
          search: props?.search,
        },
      }),
    {
      enabled: Boolean(props?.search),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return {
    data,
    contacts,
    contactLoading,
    isLoading,
    chat,
    agentAssign,
    messages,
    fetchNextPage,
    hasNextPage,
    isFetching,
    refetch,
    chatRefetch,
    messageRefetch,
    isError,
    sendMessage,
    messagesLoading,
    sendMessageLoading,
    chatFetchNextPage,
    chatHasNextPage,
    uploadFile,
  };
};

export default useChats;
