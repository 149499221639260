import React, { useState } from "react";
import styles from "./style.module.scss";
import moment from "moment";
import {
  IncommingIcon,
  OutgoingIcon,
  PhoneCallIcon,
  ShareIcon,
} from "../../../../assets/icons";
import CustomAudioPlayer from "../AudioMessage";
import AudioMessage from "../AudioMessage/AudioMessage";
import ReactPlayer from "react-player";
import { Box } from "@mui/material";
import { StayPrimaryLandscapeTwoTone } from "@mui/icons-material";
import GalleryPopup from "../Gallery";

const ChatMessage = ({
  sender,
  time,
  content,
  file,
  out,
  data,
  message,
  nextMessage,
  prevMessage,
  setReply,
  flatMessages,
}) => {
  const isOther = !out;
  const URL = message?.Media?.Webpage?.URL;
  const isAudio = message?.Media?.Document?.MimeType?.includes("audio");
  const isVideo = message?.Media?.Document?.MimeType?.includes("video");
  const Image = message?.Media?.Photo;
  const isCall = message?.Action?.Duration > 0;
  const isMissedCall = message?.Action?.Duration < 1;
  const formattedDate = moment.unix(time).format("HH:mm");
  const duration = moment.duration(message?.Action?.Duration, "seconds");
  const otherUserName = data?.Users?.find((user) => !user?.Self)?.Username;
  const [openGallery, setOpenGallery] = useState(false);
  const replyMsg = flatMessages?.find(
    (item) => item?.ID === message?.ReplyTo?.ReplyToMsgID
  );
  const gallery = message?.Media?.links?.map((item) => ({
    original: `https://test.cdn.tripoasia.com/${item}`,
    thumbnail: `https://test.cdn.tripoasia.com/${item}`,
  }));
  const formattedDuration =
    duration.asMinutes() >= 1
      ? `${Math.floor(duration.asMinutes())}min ${duration.seconds()}s`
      : `${duration.seconds()}s`;

  return (
    <>
      <div
        className={`${styles.message} ${isOther ? styles.other : styles.self}`}
      >
        <div className={styles.header}>
          <span className={styles.sender}>{isOther && otherUserName}</span>
          <span className={styles.time}>{formattedDate || "--:--"}</span>
        </div>

        {Image && (
          <img
            alt="default"
            className={styles.image}
            onClick={() => setOpenGallery(true)}
            src={
              `https://test.cdn.tripoasia.com/${message?.Media?.links?.[0]}` ||
              "/default_image.png"
            }
          />
        )}
        {isAudio && (
          <AudioMessage
            url={`https://test.cdn.tripoasia.com/${message?.Media?.links?.[0]}`}
          />
        )}
        {isVideo && (
          <Box className={styles.video}>
            <ReactPlayer
              controls
              url={`https://test.cdn.tripoasia.com/${message?.Media?.links?.[0]}`}
            />
          </Box>
        )}

        {content ? (
          <div className={styles.body}>
            <p className={styles.content}>
              {message?.ReplyTo?.ReplyToMsgID && (
                <span className={styles.reply_txt}>{replyMsg?.Message}</span>
              )}
              {content}
            </p>
          </div>
        ) : null}
        {URL && (
          <div className={styles.body}>
            <a
              href={URL}
              target="_blank"
              rel="noreferrer"
              className={styles.url_content}
            >
              {URL}
            </a>
          </div>
        )}
        {isMissedCall && (
          <div className={styles.missed_call}>
            <div className={styles.icon}>
              <PhoneCallIcon />
            </div>
            <div className={styles.content}>
              <p>Canceled call</p>
              <div className={styles.date}>
                {isOther ? <IncommingIcon /> : <OutgoingIcon />}
                <p>{formattedDate}</p>
              </div>
            </div>
          </div>
        )}
        {isCall && (
          <div className={styles.call}>
            <div className={styles.icon}>
              <PhoneCallIcon />
            </div>
            <div className={styles.content}>
              <p>Outgoing call</p>
              <div className={styles.date}>
                {isOther ? <IncommingIcon /> : <OutgoingIcon />}
                <p>
                  {formattedDate}, {formattedDuration}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className={styles.share} onClick={() => setReply(message)}>
          <ShareIcon />
        </div>
      </div>

      <GalleryPopup
        isOpen={openGallery}
        close={() => setOpenGallery(false)}
        images={gallery}
      />
    </>
  );
};

export default ChatMessage;
