import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useChats from "../../hooks/api/useChatsAPI";

const useLayout = () => {
  const navigate = useNavigate();
  const [statedChats, setStatedChats] = useState([]);
  const [permission, setPermission] = useState(null);
  const { hashId } = useParams();

  const onChatSuccess = (res) => {
    const lastPrevChat = statedChats?.[0];
    const newChat = res?.pages?.[0]?.payload?.chats?.[0];

    if (
      lastPrevChat &&
      lastPrevChat?.LastMessage?.ID !== newChat?.LastMessage.ID
    ) {
      sendNotification(newChat);
    }

    setStatedChats(res?.pages?.[0]?.payload?.chats);
    return res;
  };

  const {
    data: chats,
    refetch: chatsRefetch,
    isLoading: chatsIsLoading,
    isError: chatsIsError,
    chatFetchNextPage,
    chatHasNextPage,
  } = useChats({
    onChatSuccess,
  });

  useEffect(() => {
    if ("Notification" in window) {
      setPermission(Notification.permission);

      if (Notification.permission === "default") {
        Notification.requestPermission().then((permission) => {
          setPermission(permission);
        });
      }
    } else {
      alert("Your browser does not support notifications.");
    }
  }, []);

  const sendNotification = (data) => {
    if (permission === "granted" && !data?.LastMessage?.Out) {
      const notification = new Notification(data?.Chat?.FirstName, {
        body: data?.LastMessage?.Message,
      });
      notification.onclick = function (event) {
        event.preventDefault();
        navigate(`/chat/${data?.Chat?.ID}/${data?.Chat?.AccessHash}`);
      };
    } else {
      return;
    }
  };

  return {
    chats,
    chatsIsLoading,
    chatsRefetch,
    chatsIsError,
    chatFetchNextPage,
    chatHasNextPage,
  };
};

export default useLayout;
