import React, { useState } from "react";
import styles from "./styles.module.scss";
import ReactPlayer from "react-player";
import { Box } from "@mui/material";

const ImagePreview = ({ file, onRemove }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  if (file?.includes("mp4"))
    return (
      <Box className={styles.video}>
        <ReactPlayer url={file} controls />
      </Box>
    );

  return (
    <div className={styles.previewItem}>
      <img
        src={file}
        alt="preview"
        className={styles.previewImage}
        // onLoad={handleImageLoad}
        // style={{ display: loading ? "none" : "block" }}
      />
      {/* {loading && <div className={styles.loadingSpinner}>Loading...</div>} */}
      <button className={styles.closeButton} onClick={() => onRemove(file)}>
        ×
      </button>
    </div>
  );
};

export default ImagePreview;
