import React, { useRef, useState } from "react";
import { AudioMessagePlayIcon } from "../../../../assets/icons";
import styles from "./style.module.scss"; // Импортируйте SCSS-модуль
import { LinearProgress } from "@mui/material";

const AudioMessage = ({ url }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  const togglePlay = () => {
    setCurrentTime(0);
    const audio = audioRef.current;
    if (audio.paused && currentTime === 0) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className={styles.audiomessage}>
      <audio ref={audioRef} src={url} />

      <div onClick={togglePlay} className={styles.control}>
        {isPlaying ? <AudioMessagePlayIcon /> : <AudioMessagePlayIcon />}
      </div>

      <div className={styles.content}>
        <div style={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={3000} />
        </div>
        <p>00:00 / 06:22</p>
      </div>
    </div>
  );
};

export default AudioMessage;
