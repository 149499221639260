import { Avatar, Box, Button, CircularProgress } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import { CloseIcon, ReplyIcon, RightPanelIcon } from "../../../../assets/icons";
import useChats from "../../../../hooks/api/useChatsAPI";
import { setOpenProfile } from "../../../../redux/chat/chatSlice";
import { stringSingleAvatar } from "../../../../utils";
import ActionRenderer from "../ActionRenderer";
import ContactUpdateModal from "../Contact/Update";
import FieldMockUp from "../FIeldMockUp";
import ChatFileUpload from "../FileUpload";
import ChatMessage from "../Message";
import { FieldSkeleton } from "../Skeleton";
import ChatTools from "../Tool";
import styles from "./style.module.scss";
import useTool from "../Tool/useTool";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export const ChatField = () => {
  const messagesEndRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [scrollHeight, setScrollHeight] = useState([]);
  const { id, hashId } = useParams();
  const dispatch = useDispatch();
  const { openProfile } = useSelector((store) => store.chat);
  const { chatData, refetch } = useOutletContext();
  const [reply, setReply] = useState({});
  const userData = useSelector((store) => store.auth);
  const {
    messages,
    chatRefetch,
    messageRefetch,
    messagesLoading,
    agentAssign,
    fetchNextPage,
    hasNextPage,
    uploadFile,
  } = useChats({
    id: id,
    hash: hashId,
  });

  const {
    payload,
    message,
    setMessage,
    audioMessage,
    clearAudio,
    uploadedFiles,
    setUploadedFiles,
  } = useTool();

  const handleScroll = () => {
    if (messagesEndRef.current) {
      setScrollHeight(messagesEndRef.current?.scrollTop);
    }
  };

  useEffect(() => {
    const scrollableContainer = messagesEndRef.current;
    if (scrollableContainer) {
      scrollableContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableContainer) {
        scrollableContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [messagesEndRef.current]);

  const onOpenCreate = () => {
    setOpen(true);
  };
  const onOpenUpdateContactModal = () => {
    setOpenUpdateModal(true);
  };

  const handleAssignAgent = () => {
    const payload = {
      chat_id: chatData?.FullUser?.ID,
      user_key: userData?.user.id,
    };
    agentAssign.mutate(payload, {
      onSuccess: () => {
        refetch();
      },
    });
  };

  const isAssignedUser = chatData?.Agent?.Id === userData?.user.id;

  const handleScrollDown = () => {
    if (messagesEndRef?.current)
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  };

  // const handleFileChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
  //   setOpen(true);
  // };

  const onFileAdd = async (e) => {
    setOpen(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    uploadFile.mutate(
      { file: e.target.files[0] },
      {
        onSuccess: async (res) => {
          setUploadedFiles(
            `https://test.cdn.tripoasia.com/${res?.payload?.link}`
          );
        },
      }
    );
  };

  const handleRemoveFile = (fileToRemove) => {
    setUploadedFiles("");
    // setUploadedFiles((prevFiles) =>
    //   prevFiles.filter((file) => file !== fileToRemove)
    // );
  };

  const groupMessagesByDate = (messages) => {
    return messages?.reduce((acc, message) => {
      const messageDate = moment.unix(message?.Date).format("YYYY-MM-DD");
      if (!acc[messageDate]) {
        acc[messageDate] = [];
      }
      acc[messageDate].push(message);
      return acc;
    }, {});
  };
  const flatMessages = messages?.pages
    ?.flatMap((page) => page.payload?.messages?.Messages)
    ?.slice();

  const groupedMessages = useMemo(() => {
    if (messages) {
      const grouped = groupMessagesByDate(flatMessages);
      Object?.keys(grouped)?.forEach((date) => {
        grouped[date].sort((a, b) =>
          moment.unix(a.Date).diff(moment.unix(b.Date))
        );
      });
      return grouped;
    }
  }, [messages]);

  return (
    <div className={styles.field}>
      {messagesLoading ? (
        <FieldSkeleton />
      ) : (
        <>
          <div className={styles.header}>
            <Box className={styles.content}>
              <div className={styles.img_box}>
                <Avatar
                  {...stringSingleAvatar(
                    [
                      chatData?.Users?.[0]?.LastName ||
                        chatData?.Users?.[0]?.FirstName,
                    ].join(" ")
                  )}
                />
              </div>
              <div className={styles.desc}>
                <h5 className={styles.name}>
                  {`${chatData?.Users?.[0]?.LastName} ${chatData?.Users?.[0]?.FirstName}`}
                </h5>
                {chatData?.Users?.[0]?.Username && (
                  <p className={styles.username}>
                    @{chatData?.Users?.[0]?.Username}{" "}
                  </p>
                )}
              </div>
            </Box>
            <Box className={styles.settings}>
              <div
                className={styles.panel}
                onClick={() => {
                  dispatch(setOpenProfile(!openProfile));
                }}
              >
                <RightPanelIcon />
              </div>
              <ActionRenderer onOpen={onOpenUpdateContactModal} />
            </Box>
          </div>
          {!Object.keys(groupedMessages)?.includes("Invalid date") ? (
            <div
              id="scrollableDiv"
              className={styles.messages}
              ref={messagesEndRef}
              style={{
                display: "flex",
                flexDirection: "column-reverse",
                scrollBehavior: "smooth",
              }}
            >
              <InfiniteScroll
                dataLength={Object.keys(groupedMessages).length || 0}
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={
                  <Box className={styles.loader}>
                    <CircularProgress />
                  </Box>
                }
                inverse={true}
                style={{ display: "flex", flexDirection: "column-reverse" }}
                scrollableTarget="scrollableDiv"
              >
                {Object.keys(groupedMessages).map((date, dateIdx) => (
                  <div key={date}>
                    <div className={styles.dateSeparator}>
                      {moment(date).calendar(null, {
                        sameDay: "[Today]",
                        lastDay: "[Yesterday]",
                        lastWeek: "D MMMM YYYY",
                        sameElse: "D MMMM YYYY",
                      })}
                    </div>
                    {groupedMessages[date].map((message, index) => {
                      return (
                        <ChatMessage
                          key={message?.ID}
                          out={message?.Out}
                          sender={message?.sender}
                          time={message?.Date}
                          content={message?.Message}
                          senderType={message?.senderType}
                          file={message?.file}
                          data={groupedMessages[date]}
                          flatMessages={flatMessages}
                          message={message}
                          nextMessage={groupedMessages[date][index + 1] || null}
                          prevMessage={groupedMessages[date][index - 1] || null}
                          setReply={setReply}
                        />
                      );
                    })}
                  </div>
                ))}
              </InfiniteScroll>
              {scrollHeight < -500 && (
                <Box onClick={handleScrollDown} className={styles.scrollBottom}>
                  <ArrowDownwardIcon />
                </Box>
              )}
            </div>
          ) : (
            <FieldMockUp text="Click the Start Chat button and start sending messages." />
          )}
          {reply?.ID && (
            <div className={styles.reply}>
              <div className={styles.content}>
                <ReplyIcon />
                <div>
                  <h4>Reply to message</h4>
                  <p>{reply?.Message}</p>
                </div>
              </div>
              <div onClick={() => setReply({})} className={styles.close_button}>
                <CloseIcon />
              </div>
            </div>
          )}
          <div className={styles.footer}>
            {chatData?.Agent?.Id && isAssignedUser ? (
              <ChatTools
                handleFileChange={onFileAdd}
                onUploadOpen={onOpenCreate}
                chatRefetch={chatRefetch}
                messageRefetch={messageRefetch}
                handleScrollDown={handleScrollDown}
                reply={reply}
                setReply={setReply}
                uploadedFiles={uploadedFiles}
                setMessage={setMessage}
                message={message}
              />
            ) : (
              <Button variant="contained" onClick={handleAssignAgent} fullWidth>
                Start Chat
              </Button>
            )}
          </div>
        </>
      )}
      <ChatFileUpload
        open={open}
        setOpen={setOpen}
        uploadedFiles={uploadedFiles}
        handleRemoveFile={handleRemoveFile}
        chatRefetch={chatRefetch}
        messageRefetch={messageRefetch}
        payload={payload}
        clearAudio={clearAudio}
        setMessage={setMessage}
        message={message}
        setUploadedFiles={setUploadedFiles}
        isLoading={uploadFile?.isLoading}
        handleScrollDown={handleScrollDown}
      />
      {openUpdateModal && (
        <ContactUpdateModal
          chatData={chatData}
          open={openUpdateModal}
          setOpen={setOpenUpdateModal}
        />
      )}
    </div>
  );
};

export default ChatField;
