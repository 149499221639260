import { Button, CircularProgress, Dialog } from "@mui/material";
import styles from "./style.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import ChatTools from "../Tool";
import { UploadIcon } from "../../../../assets/icons";
import ImagePreview from "../ImagesPreview";
import useChats from "../../../../hooks/api/useChatsAPI";

export default function ChatFileUpload({
  open,
  setOpen,
  uploadedFiles,
  handleRemoveFile,
  setUploadedFiles,
  chatRefetch,
  messageRefetch,
  payload,
  clearAudio,
  setMessage,
  message,
  isLoading,
  handleScrollDown,
}) {
  const handleClose = () => {
    setOpen(false);
    setUploadedFiles(null);
  };

  const { sendMessage, sendMessageLoading } = useChats();

  const handleSendMessage = () => {
    sendMessage(payload, {
      onSuccess: () => {
        chatRefetch();
        clearAudio();
        messageRefetch();
        handleClose();
        setMessage("");
        setTimeout(() => {
          handleScrollDown();
        }, 1500);
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <div className={styles.box}>
        <CloseIcon onClick={handleClose} className={styles.close} />
        <div className={styles.header}>
          <div className={styles.add_user}>
            <UploadIcon />
          </div>
          <div className={styles.content}>
            <h3>Upload</h3>
            <p>1 Images uploaded</p>
          </div>
        </div>
        <div className={styles.body}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <div className={styles.previews}>
                {/* {uploadedFiles?.map((file, index) => ( */}
                <ImagePreview
                  file={uploadedFiles}
                  onRemove={handleRemoveFile}
                />
                {/* ))} */}
              </div>
              <ChatTools
                setMessage={setMessage}
                message={message}
                isUpload
                uploadedFiles={uploadedFiles}
              />
            </>
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.btn_group}>
            <Button className={styles.cancel_button} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={sendMessageLoading}
              className={styles.save_button}
              onClick={handleSendMessage}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
