import { Avatar, Box, CircularProgress } from "@mui/material";
import classNames from "classnames";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router-dom";
import { stringSingleAvatar } from "../../../../utils/index.js";
import styles from "./style.module.scss";

const Chats = ({
  chats,
  setLeadFormOpen,
  chatFetchNextPage,
  chatHasNextPage,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <div id="scrollableDiv" style={{ height: "100%", overflow: "auto" }}>
      <InfiniteScroll
        dataLength={chats?.length || 0}
        next={chatFetchNextPage}
        hasMore={chatHasNextPage}
        style={{ overflow: "hidden", height: "100%" }}
        scrollableTarget="scrollableDiv"
        loader={
          <Box className={styles.loader}>
            <CircularProgress />
          </Box>
        }
      >
        {chats?.map((group) => (
          <div key={group.date} className={styles.date_group}>
            <div className={styles.date}>{group.date}</div>
            <div className={styles.group_box}>
              {group.users.map((user, userIndex) => {
                const isActive = user.chat_id?.toString() === id;
                return (
                  <Box
                    key={userIndex}
                    className={classNames(styles.user, {
                      [styles.active]: isActive,
                    })}
                    onClick={() => {
                      setLeadFormOpen(false);
                      navigate(`/chat/${user.chat_id}/${user.hash}`);
                    }}
                  >
                    <div className={styles.userInfo}>
                      <Avatar
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                        {...stringSingleAvatar(
                          [user?.last_name || user?.first_name].join(" ")
                        )}
                      />
                      <div className={styles.details}>
                        <div className={styles.userName}>
                          {user?.deleted ? "Deleted account" : user.name}
                        </div>
                        <div className={styles.platform}>
                          {user.last_message}
                        </div>
                        {user?.status && (
                          <div className={styles.status}>
                            <span className={styles.status_text}>
                              {user.status}
                            </span>
                          </div>
                        )}
                        {!user?.assigned && (
                          <div className={styles.unassigned}>
                            <span className={styles.dot} />
                            <p className={styles.unassigned_text}>Unassigned</p>
                          </div>
                        )}
                        {user?.assigned && (
                          <div className={styles.assigned}>
                            <span className={styles.dot} />
                            <p className={styles.assigned_text}>
                              {user?.assigned_agent}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className={styles.unreadTime}>
                        <div className={styles.time}>{user.time}</div>
                        {user.unread ? (
                          <div className={styles.unread}>{user.unread}</div>
                        ) : null}
                      </div>
                    </div>
                  </Box>
                );
              })}
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default Chats;
